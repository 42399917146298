<template>
  <div>
    <div class="greyBackground"></div>
    <v-card class="greyColor" elevation="0">
      <h2 class="faledia-caption">Grundvorstellungen besitzen</h2>
      <v-card outlined>
        <v-card-title>Was sollen Schüler:innen lernen?</v-card-title>
        <v-card-text
          >Die Kinder können...
          <ul>
            <li>
              zu (simulierten) Alltagssituationen oder Gegenständen, die den
              verschiedenen Grundvorstellungen entsprechen, passende
              Rechenoperationen nennen, ohne dabei die Grundvorstellung benennen
              zu müssen
            </li>
            <li>Sachsituationen einer Rechenoperation (begründet) zuordnen</li>
            <li>zu einer Sachsituation die passende Rechenaufgabe notieren</li>
          </ul></v-card-text
        >
      </v-card>
      <br />
      <h3 class="faledia-caption">
        Was sollten Sie zu Grundvorstellungen wissen
      </h3>
      <div>
        <p>
          Zur Entwicklung eines tragfähigen Operationsverständnisses müssen
          <strong>Grundvorstellungen zu Rechenoperationen</strong> aufgebaut
          werden. Unter Grundvorstellungen kann man hier, die für das
          Mathematiklernen wichtigen Bedeutungszuschreibungen verstehen, die
          dazu beitragen, dass die Kinder sich unter einer Rechenoperation
          konkrete Aktivitäten vorstellen können und das Rechnen nicht auf das
          Manipulieren mit Symbolen reduzieren.
        </p>
        <p>
          Die folgende Tabelle zeigt die Grundvorstellungen zu den verschiedenen
          Rechenoperationen. In der Addition und der Subtraktion lassen sich
          jeweils drei Grundvorstellungen unterscheiden, für die Division gibt
          es hauptsächlich zwei Grundvorstellungen.
        </p>
      </div>

      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr style="text-align: center">
              <td colspan="2">Addition</td>
            </tr>
            <tr>
              <td>Hinzufügen</td>
              <td>
                Zu einer Anzahl von Objekten werden weitere Objekte
                hinzugefügt.<br />
                Beispiel: Sandra hat 3 Gummibärchen. Sie nimmt sich noch 5
                Weitere aus der Tüte, wie viele hat sie jetzt?
              </td>
            </tr>
            <tr>
              <td>Zusammenfassen</td>
              <td>
                Zwei Mengen werden zusammengelegt. <br />
                Beispiel: Sandra hat 5 Gummibärchen. Güney hat ebenfalls 5
                Gummibärchen. Wie viele Gummibärchen haben sie zusammen?
              </td>
            </tr>
            <tr>
              <td>Vergleichen</td>
              <td>
                Zwei Mengen werden verglichen, indem ihre Differenz additiv
                ermittelt wird. <br />
                Beispiel: Sandra hat 8 Gummibärchen, aber Güney hat 2 mehr. Wie
                viele Gummibärchen hat Güney?
              </td>
            </tr>
            <tr style="text-align: center">
              <td colspan="2">Subtraktion</td>
            </tr>
            <tr>
              <td>Abziehen</td>
              <td>
                Eine Anzahl von Objekten wird einer Menge weggenommen. Es bleibt
                ein Rest bestehen. <br />
                Beispiel: Julia hat 5 Kekse, sie isst 2. Wie viele Kekse hat sie
                noch über?
              </td>
            </tr>
            <tr>
              <td>Ergänzen</td>
              <td>
                Ein Unterschied wird dynamisch bestimmt. <br />
                Beispiel: Elias möchte einen Ball kaufen. Der Ball kostet 15€,
                er hat aber nur 10€ dabei. Wie viel Euro muss er aus der
                Spardose nehmen, damit er den Ball bezahlen kann?
              </td>
            </tr>
            <tr>
              <td>Vergleichen</td>
              <td>
                Ein Unterschied wird statisch bestimmt. <br />
                Beispiel: Ute ist 125cm groß. Benjamin ist 133cm groß. Um wie
                viele Zentimeter ist Benjamin größer?
              </td>
            </tr>
            <tr style="text-align: center">
              <td colspan="2">Division</td>
            </tr>
            <tr>
              <td>Aufteilen</td>
              <td>
                Die Gruppengröße ist bekannt. Die Gruppenanzahl muss bestimmt
                werden. <br />
                Beispiel: In der Klasse sind 24 Kinder. In jede Gruppe sollen 4
                Kinder. Wie viele Gruppen müssen gebildet werden?
              </td>
            </tr>
            <tr>
              <td>Verteilen</td>
              <td>
                Die Gruppenanzahl ist bekannt. Die Gruppengröße muss bestimmt
                werden. <br />
                Beispiel: In der Klasse sind 24 Kinder. Es sollen 6 Gruppen
                gebildet werden. Wie viele Kinder sind in einer Gruppe?
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <br />

      <div>
        <p>
          Verfügt ein Kind beispielsweise bei der Addition nur über die
          Grundvorstellung des dynamischen Hinzufügens, so könnte es durchaus
          Schwierigkeiten bei Aufgaben des eher statischen Vergleichens
          bekommen.
        </p>
        <p>
          Nachdem Sie nun einen Überblick über die Grundvorstellungen der
          Addition, Subtraktion und Division erhalten haben, werden im Folgenden
          die drei Grundvorstellungen der Multiplikation dargestellt und anhand
          von Beispielen gezeigt, welche Rechengeschichten zu welcher bzw. zu
          keiner Grundvorstellung passen.
        </p>
      </div>

      <div style="background-color: white">
        <LernbausteinGruppen
          :id="LBSTGruppen[0].id"
          :checkAfterHowManyItems="LBSTGruppen[0].checkAfterHowManyItems"
          :checkAfterExactItemCount="LBSTGruppen[0].checkAfterExactItemCount"
          :showGroupNames="LBSTGruppen[0].showGroupNames"
          :workOrder="LBSTGruppen[0].workOrder"
          :possibleCategories="LBSTGruppen[0].possibleCategories"
          :elements="LBSTGruppen[0].elements"
          :responses="LBSTGruppen[0].responses"
        >
          <template v-slot:alternative-content>
            <v-simple-table>
              <template>
                <tbody>
                  <tr style="text-align: center">
                    <td colspan="2">Multiplikation</td>
                  </tr>
                  <tr>
                    <td>Wiederholen / zeitlich-sukzessiv</td>
                    <td>
                      Eine Anzahl von Objekten wird zeitlich wiederholt
                      zusammengefasst.<br />
                      Beispiele:
                      <ol>
                        <li>
                          Fiona macht jeden Tag 30 Minuten Hausaufgaben. Wie
                          lange macht sie in 5 Tagen Hausaufgaben?
                        </li>
                        <li>
                          Tim geht dreimal in den Keller und holt immer vier
                          Flaschen Apfelsaft. Wie viele Flaschen hat er geholt?
                        </li>
                        <li>
                          Jana geht jede Woche 12 km joggen. Wie viele Kilometer
                          ist sie nach 6 Wochen gejoggt?
                        </li>
                        <li>
                          Steven isst jeden Tag zwei Scheiben Brot. Wie viele
                          Scheiben hat er in einer Woche gegessen?
                        </li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <td>Zusammenfassen / Räumlich-simultan</td>
                    <td>
                      Mehrere Mengen mit gleicher Anzahl von Objekten werden
                      wiederholt zusammengefasst.<br />
                      Beispiele:
                      <ol>
                        <li>
                          Eine Tafel Schokolade hat 3 Stück Schokolade pro
                          Riegel. 5 solcher Riegel bilden eine Tafel. Wie viele
                          Stücke Schokolade hat eine Tafel?
                        </li>
                        <li>
                          An einem Gruppentisch in der Klasse sitzen 5 Kinder.
                          Es gibt 6 Gruppentische. Wie viele Kinder sind in der
                          Klasse?
                        </li>
                        <li>
                          In eine Schüssel passen 6 Brötchen. Es stehen 3
                          Schüsseln auf dem Tisch. Wie viele Brötchen stehen
                          insgesamt auf dem Tisch?
                        </li>
                        <li>
                          Lilly kauft 3 Beutel Zitronen. In einem Beutel sind
                          jeweils 5 Zitronen. Wie viele Zitronen hat Lilly nun?
                        </li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <td>Vergleichen</td>
                    <td>
                      Es werden multiplikative Vergleiche zwischen Größen und
                      Anzahlen angestellt.<br />
                      Beispiele:
                      <ol>
                        <li>
                          Kira arbeitet 20 Stunden in der Woche, Andrea arbeitet
                          doppelt so viel. Wie viele Stunden arbeitet Andrea?
                        </li>
                        <li>
                          Tim geht zweimal die Woche joggen. Lars geht doppelt
                          so oft joggen. Wie oft joggt Lars?
                        </li>
                        <li>
                          Du hast drei Bonbons. Deine Schwester hat dreimal so
                          viele Bonbons wie du. Wie viele Bonbons hat deine
                          Schwester?
                        </li>
                        <li>
                          Meike bekommt 8 Euro Taschengeld im Monat. Sven
                          bekommt viermal so viel. Wie viel Taschengeld bekommt
                          Sven?
                        </li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <td>Keinerlei Grundvorstellung</td>
                    <td>
                      Ebenso können Rechengeschichten keinerlei multiplikative
                      Struktur aufweisen. Hier passen zwar die Zahlen teilweise
                      zu Multiplikationsaufgaben und deren Ergebnis, aber es ist
                      keinerlei Grundvorstellung sichtbar.<br />
                      Beispiele:
                      <ol>
                        <li>
                          Für 12 Kilometer Fahrradweg brauchen zwei Sportler in
                          etwa 20 Minuten.
                        </li>
                        <li>
                          Fünf Erwachsene und zwei Kinder essen zusammen
                          insgesamt 10 Brötchen.
                        </li>
                        <li>
                          Drei Omas gehen mit ihren insgesamt vier Hunden
                          zusammen 12 Minuten spazieren.
                        </li>
                        <li>
                          Im 2 Wochen Urlaub hat Samuel seiner Freundin
                          insgesamt 5 Postkarten aus 3 verschiedenen Ländern
                          geschickt.
                        </li>
                      </ol>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </LernbausteinGruppen>
      </div>

      <br />

      <div>
        <p>
          Dieses Wissen um Grundvorstellungen im Allgemeinen ermöglicht es nun
          der Lehrkraft, den Kindern Aufgaben zu stellen, die die Kinder mit
          allen möglichen Grundvorstellungen konfrontieren, sodass die
          Schülerinnen und Schüler ein möglichst umfassendes Bild der
          Operationen aufbauen können.
        </p>
      </div>
      <br />
      <AppLiteraturOV />
    </v-card>
    <AppBottomNavOV
      back="/operationsverstaendnis/hintergrundwissen"
      next="/operationsverstaendnis/hintergrundwissen/darstellungen"
    />
  </div>
</template>

<script>
import AppLiteraturOV from "@/common/AppLiteraturOV";
import AppBottomNavOV from "@/common/AppBottomNavOV";
import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import LernbausteinGruppen_OGVH_Gruppen from "@/components/faledia/seiten/ove/beispiele/OGVH_Gruppen";
import eventBus from "@/common/EventBus";
export default {
  components: {
    AppBottomNavOV,
    LernbausteinGruppen,
    AppLiteraturOV,
  },
  data: () => ({
    LBSTGruppen: [LernbausteinGruppen_OGVH_Gruppen],
  }),
  methods: {
    allowToCollapse(collapseId) {
      eventBus.$emit(collapseId);
    },
  },
};
</script>

<style scoped></style>
